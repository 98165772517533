import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { Fade } from 'react-awesome-reveal'
import ReactMarkdown from 'react-markdown'

export const FaqAccordionItem = ({ item, isRequested }) => {
  const accordionItemRef = useRef(null)

  useEffect(() => {
    if (typeof window !== 'undefined' && isRequested) {
      setTimeout(() => {
        window.scrollTo(0, accordionItemRef.current.offsetTop)
      }, 400)
    }
  }, [isRequested])

  return (
    <Fade triggerOnce>
      <div ref={accordionItemRef}>
        <AccordionItem uuid={item.slug}>
          <AccordionItemHeading>
            <AccordionItemButton>{item.title}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ReactMarkdown>{item.body}</ReactMarkdown>
          </AccordionItemPanel>
        </AccordionItem>
      </div>
    </Fade>
  )
}

FaqAccordionItem.propTypes = {
  item: PropTypes.object.isRequired,
  isRequested: PropTypes.bool,
}
